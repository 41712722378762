<template>
  <v-card
    class="mx-auto"
    max-width="500"
  >
    <v-card-title class="text-h6 font-weight-regular justify-space-between">
      <span>{{ currentTitle }}</span>
      <v-avatar
        color="primary"
        size="24"
        v-text="step"
      />
    </v-card-title>

    <v-window v-model="step">
      <v-window-item :value="1">
        <v-card-text>
          <span class="text-caption text-grey-darken-1">
            <p class="text-justify">
              Peniaga yang dihormati,
            </p>
            <p class="text-justify">
              Adalah dimaklumkan bahawa XOX ialah pengguna data berdaftar di bawah Akta Perlindungan Data Peribadi 2010 (“APDP”).
            </p>
            <p class="text-justify">
              Sebagai rakan kongsi kami yang dihargai, kami ingin mengingatkan bahawa anda juga terikat untuk mematuhi garis panduan PDPA dan dasar privasi XOX untuk mengumpul, menggunakan, mendapatkan dan memadam (jika ada) data/maklumat peribadi pelanggan. Sila rujuk garis panduan mengenai
              <a href="http://onesys.onexox.my/form/7prinsip.png"> 7 Prinsip Perlindungan Data Peribadi</a>
              yang dikeluarkan oleh Jabatan Perlindungan Data Peribadi (“JPDP”) dan dasar
              <a href="https://xox.com.my/privacystatement/details/"> Privacy XOX
              </a></p>
            <p class="text-justify">
              Terima kasih di atas perhatian anda terhadap perkara ini.
            </p>
            <p class="text-justify">
              Sijil Pendaftaran XOX yang dikeluarkan dibawah APDP:
            </p>
            <p>
              <a
                href="http://onesys.onexox.my/form/Certificate-XOX-Com-Sdn-Bhd.pdf"
                target="_blank"
              > i) XOX COM</a>
            </p>
            <p>
              <a
                href="http://onesys.onexox.my/form/XOX-BHD-PDP-Certificate.pdf"
                target="_blank"
              >ii) XOX Berhad</a>
            </p>
            <p>
              <a
                href="http://onesys.onexox.my/form/Certificate-XOX-Media-Sdn-Bhd.pdf"
                target="_blank"
              >iii) XOX Media</a>
            </p></span>
        </v-card-text>
      </v-window-item>

      <v-window-item :value="2">
        <v-card-text>
          <span class="text-caption text-grey-darken-1">
            <p class="text-justify">
              Kerjasama berterusan anda dengan kami menyiratkan penerimaan syarat ini dan sebarang ketidakpatuhan yang dikeluarkan oleh JPDP akan ditanggung oleh peniaga mengikut perjanjian
              <a href="https://onesys.onexox.my/form/Dealer_Agreement_20191220.pdf">Terma dan Syarat
              </a>yang terkini.
            </p>
            <v-container
              fluid
            >
              <!-- <td>
                <input
                  type="checkbox"
                  v-model="checkbox"
                >
              </td>
              <td class="text-xs-right">
                Saya dengan ini mematuhi garis panduan perlindungan data peribadi dan bersetuju dengan perjanjian/terma dan syarat yang terkini. Saya akan bertanggungjawab sekiranya terdapat sebarang ketidakpatuhan kepada APDP atau peraturannya dan tidak akan menganggap XOX bertanggungjawab atau menuntut  ganti rugi.
              </td> -->
              <!-- <v-checkbox
                :true-value="1"
                :false-value="0"
                :rules="[v => v.length > 0 || 'You must agree to submit!']"
                v-model="checkbox"
              >
                <template #label>
                  <v-card-text>
                    <span class="text-caption text-grey-darken-1">
                      <p
                        class="text-xs-justify"
                      >
                        Saya dengan ini mematuhi garis panduan perlindungan data peribadi dan bersetuju dengan perjanjian/terma dan syarat yang terkini. Saya akan bertanggungjawab sekiranya terdapat sebarang ketidakpatuhan kepada APDP atau peraturannya dan tidak akan menganggap XOX bertanggungjawab atau menuntut  ganti rugi.
                      </p>
                    </span>
                  </v-card-text>
                </template>
              </v-checkbox> -->
              <v-checkbox
                v-model="checkbox"
              >
                <template #label>
                  <v-card-text>
                    <span class="text-caption text-grey-darken-1">
                      <p
                        class="text-xs-justify"
                      >
                        Saya dengan ini mematuhi garis panduan perlindungan data peribadi dan bersetuju dengan perjanjian/terma dan syarat yang terkini. Saya akan bertanggungjawab sekiranya terdapat sebarang ketidakpatuhan kepada APDP atau peraturannya dan tidak akan menganggap XOX bertanggungjawab atau menuntut  ganti rugi.
                      </p>
                    </span>
                  </v-card-text>
                </template></v-checkbox>
            </v-container>
          </span>
        </v-card-text>
      </v-window-item>
    </v-window>

    <v-divider />

    <v-card-actions>
      <v-btn
        v-if="step > 1"
        variant="text"
        @click="step--"
      >
        Back
      </v-btn>
      <v-spacer />
      <v-btn
        v-if="step > 1"
        @click="close"
      >
        Cancel
      </v-btn>
      <v-btn
        v-if="step > 1 "
        :disabled="checkbox == false"
        @click="submit"
      >
        Submit
      </v-btn>
      <v-btn
        v-if="step <2"
        @click="step++"
      >
        Next
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { createPostParams } from '@/rest'
export default {
  data: () => ({
    step: 1,
    checkbox: false,
  }),

  computed: {
    currentTitle () {
      switch (this.step) {
        case 1: return 'Notis Privasi XOX'
        case 2: return 'Perlindungan Data Peribadi'
        default: return 'Notis Privasi XOX'
      }
    },
  },
  methods: {
    close: function () {
      this.$emit('closeDialog', true)
    },
    snackbar: function () {
      this.$emit('snackbar', true)
    },
    async submit () {
      const params = createPostParams({
        consent: '1',
      })
      await this.$rest.post('postConsentPdpa.php', params)
      this.close()
      this.snackbar()
    },
  },
}
</script>
